import { FC } from 'react'
import classNames from 'classnames'
import Typography from 'components/Typography'
import Button from 'components/Button'
import { TSectionProps } from '../../../types'
import { searchButtonAboutEvent } from '../../../events'
import styles from './About.module.scss'

const About: FC<TSectionProps> = ({ id, className, customRef }) => {
  return (
    <section
      id={id}
      className={classNames(styles.ecHomeAbout, className)}
      ref={customRef}
    >
      <div className={styles.ecHomeAboutImageWrapper}>
        <iframe
          height="394"
          src="https://www.youtube.com/embed/3Lna4Vyy4N4?si=V5AlVT_rigeHX-zb"
          title="EmCasa - Todos os imóveis, com os melhores corretores"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
      <div className={styles.ecHomeAboutContent}>
        <Typography
          bold
          as="h2"
          variant="Title"
          className={styles.ecHomeAboutTitle}
        >
          Todos os imóveis, com os melhores corretores
        </Typography>
        <Typography className={styles.ecHomeAboutTitle}>
          Sabemos que a compra de um imóvel tem diferentes propósitos. Por isso
          nossos corretores são capacitados para te ajudar em qualquer fase da
          vida e temos imóveis que se encaixam perfeitamente em cada momento.
        </Typography>
        <Button
          element="a"
          theme="pink"
          href="/imoveis"
          className={styles.ecPageHomeSection}
          onClick={() => searchButtonAboutEvent()}
        >
          Buscar imóvel
        </Button>
      </div>
    </section>
  )
}

export default About
